<template>
  <CContainer fluid class="p-0">
    <base-landing-result :is-result-page="isResultPage">
      <!-- Header -->
      <template #header="{ contact, agent }">
        <div
            class="container-fluid header"
            :style="{
        backgroundImage:
          'url(' + require('../../assets/images/result_banner.png') + ')',
        backgroundSize: 'cover',
        backgroundPositionY: 'center'
      }"
        >
          <Header page="result" :agent="agent" :contact="contact">
            <template #heading>
              Hi {{ (contact && contact.owner_firstname) || "Neighbor" }},
            </template>
            <template #content>
              <p class="header-text" v-if="agent.profile_message">
                {{ decodeURIComponent(agent.profile_message) }}
              </p>
              <p class="" v-else>
                <template v-if="agent.firstname && agent.firstname2"
                >We have lived and worked as real estate agents
                </template>
                <template v-else
                >I have lived and worked as a real estate agent</template
                >
                in your community and know how to get the best selling price for
                your home.
              </p>
            </template>
            <template #form>
              <HeaderBottomButtons :contact="contact" :agent="agent" />
            </template>
          </Header>
        </div>
      </template>
      <!-- End of Header -->

      <!-- Estimates -->
      <template #estimations="{ contact, agent }">
        <div class="container">
          <div class="row hv-estimation-boxes mt-20">
            <div class="col col-12">
              <div class="">
                <ResultBlock
                    :contact="contact"
                    :agent="agent"
                />
              </div>
            </div>
          </div>
        </div>
      </template>
      <!-- End of Estimates -->

      <!-- Property Section -->
      <template #property="{ contact, agent, mapConfig, markerConfig, propertySpecs, valuations, handleNewAddress }">
        <div class="container">
          <CRow v-if="contact && markerConfig" class="hv-landing-map">
            <CCol>
              <div style="height: 500px;position: relative;">
                <Map :map-config="mapConfig" :marker-config="markerConfig" />
                <div id="property">
                  <div id="propertyDetail" class="property-detail">
                    <p class="text-white p-2" v-if="contact">
                      {{ contact.street_address_1 }}{{ contact.street_address_2 }},
                      {{ contact.city }}, {{ contact.state }}  {{ contact.zip}}
                      <br />
                      <span v-html="propertySpecs" id="propertySpecs" />
                    </p>
                  </div>
                </div>
              </div>
            </CCol>
          </CRow>
        </div>

        <div v-if="valuations" class="row hv-landing-allsales mt-20">
          <div class="col">
            <OtherSales />
          </div>
        </div>

        <SearchBox class="mt-20" :agent_id="agent.agent_id" :contact="contact" @newAddress="handleNewAddress" />
      </template>
      <!-- End of Property Section-->

      <!-- Featured Section -->
      <template #featured="{ listings }">
      <div class="container bg-light pt-3 hv-landing-properties">
        <div class="row">
          <div class="col">
            <p class="pb-2 properties-title">My Featured Properties</p>
            <FeaturedBlock :listings="listings" />
          </div>
        </div>
      </div>
      </template>
      <!-- End of Featured Section -->
    </base-landing-result>
  </CContainer>
</template>

<script>
import BaseLandingResult from "@/components/LandingResult/BaseLandingResult";
import Header from "@/components/Header/Header";
import HeaderBottomButtons from "@/components/LandingResult/components/HeaderBottomButtons";
import ResultBlock from "@/components/LandingResult/components/ResultBlock";
import Map from "@/components/LandingResult/components/Map";
import SearchBox from "@/components/LandingResult/components/SearchBox";
import FeaturedBlock from "@/components/LandingResult/components/FeaturedBlock";
import OtherSales from "@/components/LandingResult/components/OtherSales";

export default {
  name: "LandingResultView",
  components: { OtherSales, BaseLandingResult, Header, HeaderBottomButtons, ResultBlock, Map, SearchBox, FeaturedBlock },
  data() {
    return {
      isResultPage: true
    }
  },
};
</script>

<style type="scss" lang="scss">
.header-text {
  font-size: 16px;
  font-weight: 400;
  font-family: 'Metro-Sans';
}

.hv-landing-properties {
  .properties-title {
    font-size: 1.875rem;
    font-weight: 300;
  }
}
.get-started-btn {
  font-size: 16px;
  font-weight: 700;
  background: #525151;
  color: #fff;
}

.header {
  padding-top: 250px;
}
</style>
