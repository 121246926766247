<template>
  <header class="d-flex justify-content-center">
    
    <div v-if="page!='adgentWidget'" class="row hv-header-content work-sans">
      <div class="col col-12 hv-hs-container">
        <div class="row">
          <div class="col-sm-12 col col-md-6 text-white d-flex flex-column justify-content-center">
            <h1 class="hv-hs-title text-white">
              <slot name='heading' />
            </h1>
            <slot name='content' />
          </div>
          <div class="col col-lg-6 col-sm-12 right-side">

            <HeaderAgentForm :agent="agent" :contact="contact" />


          </div>

        </div>
        <div class="row">
           <slot name='form' />
        </div>

      </div>
    </div>


    <header v-else>
      <section className="header-content">
        <div id="impact-widget-container">
          <div id="impact-widget-wrapper">
            <div className="container-fluid">
              <h1>
                <slot name='heading' />
              </h1>

              <slot name='content' />

            </div>
          </div>
        </div>
      </section>
    </header>


  </header>

</template>

<script>

import HeaderAgentForm from '@/components/LandingResult/components/HeaderAgentForm';

export default {
  name: 'Header',
  components: {
    HeaderAgentForm,
  },

  props: {
    page: { type: String, default: '' },
    agent: Object,
    contact: Object,
  },
  created() {
  },
}
</script>

<style scoped lang="scss">

.hv-hs-container  {
  background: #F7F7F7;
  padding: 20px 40px;
  max-width: 973px;

  p, h1 {
    color:  #000F0C !important;
  }
}
.header-description {
  line-height: 250%;
}

.hv-hs-title {
  font-size: 48px;
  font-weight: 400;
  letter-spacing: 4px;
  position: relative;
  top: -1rem; 
  font-family: 'Metro-Sans';
}
  
.hv-header-content {
  padding-bottom: 50px;
  padding-top: 50px;
}

.hv-header-content {
  max-width: 1140px;
}

.right-side {
  .card {
    border-color: transparent !important;
    margin-bottom: 0;
    padding: 0 25px 0 0;
  }
}

</style>  