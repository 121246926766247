var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CContainer',{staticClass:"p-0",attrs:{"fluid":""}},[_c('base-landing-result',{attrs:{"is-result-page":_vm.isResultPage},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var contact = ref.contact;
var agent = ref.agent;
return [_c('div',{staticClass:"container-fluid header",style:({
      backgroundImage:
        'url(' + require('../../assets/images/result_banner.png') + ')',
      backgroundSize: 'cover',
      backgroundPositionY: 'center'
    })},[_c('Header',{attrs:{"page":"result","agent":agent,"contact":contact},scopedSlots:_vm._u([{key:"heading",fn:function(){return [_vm._v(" Hi "+_vm._s((contact && contact.owner_firstname) || "Neighbor")+", ")]},proxy:true},{key:"content",fn:function(){return [(agent.profile_message)?_c('p',{staticClass:"header-text"},[_vm._v(" "+_vm._s(decodeURIComponent(agent.profile_message))+" ")]):_c('p',{},[(agent.firstname && agent.firstname2)?[_vm._v("We have lived and worked as real estate agents ")]:[_vm._v("I have lived and worked as a real estate agent")],_vm._v(" in your community and know how to get the best selling price for your home. ")],2)]},proxy:true},{key:"form",fn:function(){return [_c('HeaderBottomButtons',{attrs:{"contact":contact,"agent":agent}})]},proxy:true}],null,true)})],1)]}},{key:"estimations",fn:function(ref){
    var contact = ref.contact;
    var agent = ref.agent;
return [_c('div',{staticClass:"container"},[_c('div',{staticClass:"row hv-estimation-boxes mt-20"},[_c('div',{staticClass:"col col-12"},[_c('div',{},[_c('ResultBlock',{attrs:{"contact":contact,"agent":agent}})],1)])])])]}},{key:"property",fn:function(ref){
    var contact = ref.contact;
    var agent = ref.agent;
    var mapConfig = ref.mapConfig;
    var markerConfig = ref.markerConfig;
    var propertySpecs = ref.propertySpecs;
    var valuations = ref.valuations;
    var handleNewAddress = ref.handleNewAddress;
return [_c('div',{staticClass:"container"},[(contact && markerConfig)?_c('CRow',{staticClass:"hv-landing-map"},[_c('CCol',[_c('div',{staticStyle:{"height":"500px","position":"relative"}},[_c('Map',{attrs:{"map-config":mapConfig,"marker-config":markerConfig}}),_c('div',{attrs:{"id":"property"}},[_c('div',{staticClass:"property-detail",attrs:{"id":"propertyDetail"}},[(contact)?_c('p',{staticClass:"text-white p-2"},[_vm._v(" "+_vm._s(contact.street_address_1)+_vm._s(contact.street_address_2)+", "+_vm._s(contact.city)+", "+_vm._s(contact.state)+" "+_vm._s(contact.zip)+" "),_c('br'),_c('span',{attrs:{"id":"propertySpecs"},domProps:{"innerHTML":_vm._s(propertySpecs)}})]):_vm._e()])])],1)])],1):_vm._e()],1),(valuations)?_c('div',{staticClass:"row hv-landing-allsales mt-20"},[_c('div',{staticClass:"col"},[_c('OtherSales')],1)]):_vm._e(),_c('SearchBox',{staticClass:"mt-20",attrs:{"agent_id":agent.agent_id,"contact":contact},on:{"newAddress":handleNewAddress}})]}},{key:"featured",fn:function(ref){
    var listings = ref.listings;
return [_c('div',{staticClass:"container bg-light pt-3 hv-landing-properties"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('p',{staticClass:"pb-2 properties-title"},[_vm._v("My Featured Properties")]),_c('FeaturedBlock',{attrs:{"listings":listings}})],1)])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }