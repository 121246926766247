<template>
  <div class="d-print-none hv-header-bottom w-100 pt-3">

      
    <div class="hv-hb-buttons col-6">
      <button class="intro-button" type="button" id="set-app-btn" v-bind:class="`btn btn-${agent.specificColor || 'primary'} btn-block`" @click="openAppointmentsWnd" data-toggle="modal" data-target=".bs-example-modal-lg">
        Get Started
      </button>

      <!-- <button class="intro-button" type="button" id="set-asses-btn" v-bind:class="`btn btn-${agent.specificColor || 'primary'} btn-block`" @click="openAssessmentWnd" data-toggle="modal" data-target=".bs-example-modal-lg">On-Site
        Assessment
      </button>

      <button class="intro-button" type="button" id="set-download-btn" v-bind:class="`btn btn-${agent.specificColor || 'primary'} btn-block`" @click="downloadReport">Download
        Your Custom Report
      </button> -->
    </div>



    <ModalAppointment :type="state.modalType" @onClose="closeWnd" :appointmentModal="state.appointmentModal" :contact="contact" id="appointment-modal-wnd" />


  </div>
</template>

<script>
import ModalAppointment from '@/components/LandingResult/components/ModalAppointment';4
import TrackingApi from '../../../api/tracking';

export const MODAL_TYPE_APPOINTMENT = 'appointment';
export const MODAL_TYPE_ASSESSMENT = 'assessment';

export default {
  name: 'HeaderBottomButtons',
  data: () => ({
    state: {
      modalType: MODAL_TYPE_APPOINTMENT,
      app_type: MODAL_TYPE_APPOINTMENT,
      ass_type: MODAL_TYPE_APPOINTMENT,
      appointmentModal: false,
    },
  }),
  props: {
    contact: Object,
    agent: Object,
  },
  methods: {
    openAppointmentsWnd() {
      this.trackClick();
      this.state.modalType = MODAL_TYPE_APPOINTMENT;
      this.state.appointmentModal = true;
    },
    openAssessmentWnd() {
      this.trackClick();
      this.state.modalType = MODAL_TYPE_ASSESSMENT;
      this.state.appointmentModal = true;
    },
    downloadReport: () => {
      print();
    },
    setAppointment() {
    },
    closeWnd(){
      this.state.appointmentModal = false;
    },
    trackClick(){
      if (this.contact){
        TrackingApi.addGetStarted(this.contact.agent_id, this.contact.id, this.contact.compaign_id, this.contact).then();
      }
    }
  },
  created(){
  },
  components: {
    ModalAppointment,
  },
}
</script>

<style scoped lang="scss">
.input-group-prepend .input-group-text:first-child {
  min-width: 160px;
}

.header-buttons-row{
  padding-top: 0px;
  padding-bottom: 0px;
}

.hv-header-bottom {

  margin-top: -55px;

  .hv-hb-buttons {
    display: flex;
    justify-content: space-between;

    .intro-button {
        margin: 0;
        padding:0.5rem 1rem;
      
    }

    button {
      background-color: #525151;
      border-color: #28a745;
    }
  }
 
}

</style>