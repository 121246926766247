<template>

  <CModal
      title="title"
      color="secondary"
      :show.sync="isShow"
      size="lg"
  >
    <template slot="header">
      <h4 class="text-left" v-if="type === MODAL_TYPE_APPOINTMENT">Set Up Appointment</h4>
      <h4 class="text-left" v-if="type === MODAL_TYPE_ASSESSMENT">On-Site Assessment</h4>
      <CButton class="float-right text-white" @click="isShow=false">X</CButton>
    </template>
    <!--
  <div id="appointment-modal-wnd" class="modal fade bs-example-modal-lg" tabindex="-1" role="dialog"
       aria-labelledby="myLargeModalLabel">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header bg-light">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
              aria-hidden="true">&times;</span></button>
          <h4 class="text-left" v-if="type === MODAL_TYPE_APPOINTMENT">Set Up Appointment</h4>
          <h4 class="text-left" v-if="type === MODAL_TYPE_ASSESSMENT">On-Site Assessment</h4>
        </div>
        <div class="modal-body">
-->
    <div class="row ">
      <div class="col col-xs-12 text-left">
        <p>
          Please complete your information below, and I will get back to you
          {{ leadInfo[type] }}
          as soon as possible.
        </p>
      </div>
    </div>

    <form class="">

      <CRow class="row" id="request-form">
        <CCol col="12">
          <CRow>
            <div class="col col-xs-12 input-group mb-2">
              <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        Name
                                    </span>
              </div>
              <input class="form-control" name="name" component="input" v-model="state.contact.name" type="text"
                     :class="{ 'is-invalid': $v.state.contact.name.$error }"
                     @change="setValue('name', $event.target.value)"
              />
              <span class="invalid-feedback"
                    v-if="!$v.state.contact.name.required && $v.state.contact.name.$error">{{
                  messages.required()
                }}</span>
              <span class="invalid-feedback"
                    v-if="!$v.state.contact.name.name_required && $v.state.contact.name.$error">{{
                  messages.name_required()
                }}</span>
            </div>
          </CRow>
          <CRow>
            <div class="col col-xs-12 input-group mb-2">
              <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        Street Address
                                    </span>
              </div>
              <input class="form-control" name="street_address_1" v-model="state.contact.street_address_1"
                     component="input"
                     type="text"
                     :class="{ 'is-invalid': $v.state.contact.street_address_1.$error }"
                     @change="setValue('street_address_1', $event.target.value)"
              />
              <span class="invalid-feedback"
                    v-if="!$v.state.contact.street_address_1.required && $v.state.contact.street_address_1.$error">{{
                  messages.required()
                }}</span>
            </div>
          </CRow>
          <CRow>
            <div class="col col-xs-12 input-group mb-2">
              <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        Street Address 2
                                    </span>
              </div>
              <input class="form-control" name="street_address_2" v-model="state.contact.street_address_2"
                     component="input"
                     type="text"
                     :class="{ 'is-invalid': $v.state.contact.street_address_2.$error }"
                     @change="setValue('street_address_2', $event.target.value)"
              />
              <span class="invalid-feedback"
                    v-if="!$v.state.contact.street_address_2.required && $v.state.contact.street_address_2.$error">{{
                  messages.required()
                }}</span>
            </div>
          </CRow>
          <CRow>
            <div class="col col-xs-12 input-group mb-2" :class="{ 'is-invalid': $v.state.contact.city.$error }">
              <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        City
                                    </span>
              </div>
              <input class="form-control" name="city" v-model="state.contact.city" component="input"
                     type="text"
                     :class="{ 'is-invalid': $v.state.contact.city.$error }"
                     @change="setValue('city', $event.target.value)"
              />
              <span class="invalid-feedback"
                    v-if="!$v.state.contact.city.required && $v.state.contact.city.$error">{{
                  messages.required()
                }}</span>
            </div>
          </CRow>
          <CRow>
            <CCol xs="12" lg="6" col="6" class="input-group mb-2"
                  :class="{ 'is-invalid': $v.state.contact.state.$error }">
              <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        State
                                    </span>
              </div>

              <input class="form-control" name="state" component="input"
                     type="text"
                     v-model="state.contact.state"
                     :class="{ 'is-invalid': $v.state.contact.state.$error }"
                     @change="setValue('state', $event.target.value)"
              />


            </CCol>


            <CCol xs="12" lg="6" col="6" class="input-group mb-2">
              <div class="input-group-prepend" :class="{ 'is-invalid': $v.state.contact.zip.$error }">
                                    <span class="input-group-text">
                                        Zip
                                    </span>
              </div>
              <input class="form-control" name="zip" v-model="state.contact.zip" component="input"
                     type="text"
                     :class="{ 'is-invalid': $v.state.contact.zip.$error }"
                     @change="setValue('zip', $event.target.value)"
              />

            </CCol>
            <CCol xs="12" lg="6" col="6" class="pt-0">
                            <span class="invalid-feedback"
                                  :class="{ 'd-inline': $v.state.contact.state.$error }"
                                  v-if="!$v.state.contact.state.required && $v.state.contact.state.$error">{{
                                messages.required()
                              }}</span>
            </CCol>
            <CCol xs="12" lg="6" col="6" class="pt-0" :class="{ 'is-invalid': $v.state.contact.zip.$error}">
              <span class="invalid-feedback" :class="{ 'd-inline': $v.state.contact.zip.$error }"
                    v-if="!$v.state.contact.zip.required && $v.state.contact.zip.$error">{{
                  messages.required()
                }}</span>
            </CCol>
          </CRow>
          <CRow>
            <div class="col col-xs-12 input-group mb-2" :class="{ 'is-invalid': $v.state.contact.phone.$error }">
              <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        Phone
                                    </span>
              </div>
              <input class="form-control" name="phone" v-model="state.contact.phone" component="input"
                     type="text"
                     :class="{ 'is-invalid': $v.state.contact.phone.$error }"
                     @change="setValue('phone', $event.target.value)"
              />
              <span class="invalid-feedback"
                    v-if="!$v.state.contact.phone.required && $v.state.contact.phone.$error">{{
                  messages.required()
                }}</span>
            </div>
          </CRow>


          <CRow>
            <div class="col col-xs-12 input-group mb-2" :class="{ 'is-invalid': $v.state.contact.email.$error }">
              <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        eMail
                                    </span>
              </div>
              <input class="form-control" name="email" v-model="state.contact.email" component="input"
                     type="text"
                     :class="{ 'is-invalid': $v.state.contact.email.$error }"
                     @change="setValue('email', $event.target.value)" />
              <span class="invalid-feedback"
                    v-if="!$v.state.contact.email.required && $v.state.contact.email.$error">{{
                  messages.required()
                }}</span>
              <span class="invalid-feedback"
                    v-if="!$v.state.contact.email.email && $v.state.contact.email.$error">{{
                  messages.email()
                }}</span>
            </div>
          </CRow>
        </CCol>

      </CRow>


    </form>


    <template slot="footer-wrapper">
      <div class="modal-footer bg-light">
        <button type="button" class="btn btn-success" @click="isShow=false">

          Close
        </button>
        <button type="button" class="btn btn-primary" @click="applyForm()">
          <CSpinner v-if="loading" size="sm" />
          Submit
        </button>
      </div>
    </template>
  </CModal><!-- /.modal-content -->
  <!-- /.modal-dialog -->

</template>

<script>

import {
  MODAL_TYPE_APPOINTMENT,
  MODAL_TYPE_ASSESSMENT,
} from '@/components/LandingResult/components/HeaderBottomButtons';

import { email, required } from 'vuelidate/lib/validators';
import { mapState } from 'vuex';
import { name_required, name_required_msg } from '@/utils/NameValidator';

export default {
  name: 'ModalAppointment',
  props: {
    type: String,
    contact: Object,
    appointmentModal: {
      type: Boolean, default: false,
    },
  },
  data: () => ({
    MODAL_TYPE_APPOINTMENT: MODAL_TYPE_APPOINTMENT,
    MODAL_TYPE_ASSESSMENT: MODAL_TYPE_ASSESSMENT,
    title: '',
    state: {
      contact: {},
    },
    isShow: false,
    leadInfo: {
      [MODAL_TYPE_APPOINTMENT]: 'set up an appointment',
      [MODAL_TYPE_ASSESSMENT]: 'on-site assessment',
    },
    messages: { //Add more messages here when you need, the key should be the same as the name of the validator
      required: () => 'This field is required',
      email: () => 'Must be a valid e-mail',
      name_required: name_required_msg,
    },
  }),
  computed: {
    ...mapState({
                  loading: state => state.Contact.loading,
                  campaignId: state => state.Contact.campaignId,
                }),
  },
  watch: {
    type: () => { // newVal , oldVal
    },
    contact: function (newVal) { // newVal , oldVal
      this.state.contact = { ...newVal };
      //console.dir(this.state.contact, this.contact);
    },
    isShow: {
      handler: function (newVal) {
        if (newVal === false) {
          this.$emit('onClose', true);
        } else {
          this.state.contact = { ...this.contact };
          this.$v.$reset();
        }
      },
      deep: true,

    },
    appointmentModal(newVal) {
      this.isShow = newVal;
    },
  },
  methods: {
    applyForm() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        // const contact = this.state.contact;
        // contact.name: [contact.owner_firstname, contact.owner_lastname || ''].join(' ').trim(),
        this.$store.dispatch('Contact/saveAppointment',
                             { contactId: this.contact.id, type: this.type, data: this.state.contact, campaignId: this.campaignId }).then(
            (result) => {
              if (result) {
                this.isShow = false;
                this.$emit('onClose', true);
              }
            });


      }
      // alert(Object.values(this.state.contact));

    },
    setValue(field, value) {
      this.state.contact[field] = value;
    },
  },
  created() {
    this.state.contact = { ...this.contact };
  },
  validations: {
    state: {
      contact: {
        name: {
          required,
          name_required,
        },
        //Yup.string().required().matches(/[\w\.]+[\s\w-_,\'] +/i, 'Please provide both first & last name').label(
        //    'Name'),
        street_address_1: {
          required,
        },
        street_address_2: {
          // required,
        },
        city: {
          required,
        },
        state: {
          required,
        },
        zip: {
          required,
        },
        email: {
          required,
          email,
        },
        phone: {
          required,
        },

      },
    },
  },

}
</script>

<style scoped lang="scss">
div#request-form .input-group {
  /*padding: 10px;*/
}

div#request-form .input-group-text {
  width: 160px;
  font-size: 1rem;
  padding: 0.21rem 0.75rem;
}

.modal-dialog  {
 
  .modal-content {

    header.modal-header {
        display: flex;
        align-items: center;
        background-color: #f5f5f5 !important;

      h4 {
        color: #333;
        font-size: 1.875rem;
        font-weight: 600;
        margin: 0;
        line-height: 1.5;
      }
    }

  }
}

</style>

