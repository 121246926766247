<template>

  <img
      v-bind:src="`${this.type === 'zillow' ? 'https://s.zillowstatic.com/pfs/static/z-logo-default.svg':
      this.$options.logos[this.type]}`"
      v-bind:alt="`${this.type === 'zillow' ? 'Real Estate on Zillow' : (this.type + ' logo')}`"
      v-bind:height="`${this.type !== 'zillow' ? '50' : ''}`"
      style="max-width: 270px"
  />
</template>

<script>
import eppraisal from     '../../../assets/images/logos/eppraisal.png';
// import attom from      '../../../assets/images/logos/attom.png';
// import homeworth from  '../../../assets/images/logos/homeworth.png';
import estated from       '../../../assets/images/logos/estated.png';
import firstteam from     '../../../assets/images/logos/firstteam.png';
import realtyMole from     '../../../assets/images/logos/realty-mole.png';


export default {
  name: 'Logo',
  props: {
    type: String,
  },
  logos: {
    eppraisal,
    //   homeworth,
    // attom,
    estated,
    firstteam,
    realtyMole
  }
}
</script>

<style scoped>

</style>