<template>
  <div class="valuation-container">
    <CRow>
      <CCol v-if="valuations">
        <h2 class="estimate-heading mt-1 text-center">Your Home Estimated Value</h2>
        <p class="mt-1 text-justify">
          We search three different valuation sources in order to provide you multiple estimates to compare.
          {{ valuationMessage }} As an expert, I can help to evaluate your home to get you the best selling price in
          today's market.
        </p>
        <div class="estimation-boxes">
          <CRow id="v-for-object" class="demo pt-2 justify-content-center">
            <CCol class="demo pt-1 justify-content-center card-deck p-0">
              <ResultValueBox v-for="(value, name) in valuations" :key="name" :type="name" :value="value" />
            </CCol>
          </CRow>
        </div >
        <CRow class="disclaimers">
          <p>
            <small>
              <ul>

                <li v-if="valuations.zillow">
                  * &copy; Zillow, Inc. 2006-{{ new Date().getFullYear() }}. Use is subject to <a
                    href="http://www.zillow.com/corp/Terms.htm" target="_blank">Terms of Use</a>. <a
                    href="http://www.zillow.com/wikipages/What-is-a-Zestimate/" target="_blank">
                  What's a Zestimate?
                </a> <a :href="zLink" target="_blank">
                  See more details
                  for {{ contact.street_address_1 }} {{ contact.street_address_2 }}, {{ contact.city }},
                  {{ contact.state }}
                  {{ contact.zip }} on
                  Zillow
                </a>
                </li>


                <li v-if="valuations.eppraisal">
                  * 2012-{{ new Date().getFullYear() }} <a href="http://eppraisal.com" target="_blank">Eppraisal.com</a>.
                  Use is subject
                  to <a href="http://www.eppraisal.com/TermsOfUse.aspx" target="_blank">Terms of Use</a>
                </li>


              </ul>
            </small>
          </p>
        </CRow>
      </CCol>
    </CRow>

    <AddressVerificationModal :isShow="isAddressVerifShow" :address="contact" @onClose="closeWnd" />

  </div>
</template>

<script>
import { mapState } from 'vuex';
import ResultValueBox from '@/components/LandingResult/components/ResultValueBox';
import lodash from 'lodash';
import AddressVerificationModal from '@/components/LandingResult/components/AddressVerificationModal';

export default {
  name: 'ResultBlock',
  components: { AddressVerificationModal, ResultValueBox },
  methods: {
    loadData() {
      return [1, 2, 3]
    },
    updateAddress() {
      this.isAddressVerifShow = false;
    },
    closeWnd() {
      this.isAddressVerifShow = false;
      this.$store.commit('Results/setAddressWndStatus', false, {root: true});
    },
  },
  watch:{

    showAddressWnd(newValue){
      this.isAddressVerifShow = newValue;
    },
  },
  props: {
    contact: { type: Object, required: true },
    agent: { type: Object, required: true },
  },
  data() {
    return {
      isAddressVerifShow: false,
    }
  },
  computed: {
    ...mapState({
                  sales: state => state.Results.sales,
                  valuations: state => state.Results.valuations,
                  resLoading: state => state.Results.loading,
                  showAddressWnd: state => state.Results.showAddressWnd,
                }),
    zLink: function () {
      return lodash.get(this.valuations, 'zillow.zillow_link') || '';
    },
    valuationMessage: function () {

      switch (Object.keys(this.valuations).length) {
        case 1:
          return `We were only able to find one estimate on the property you entered.`;
        case 2:
          return `We were only able to find two estimates on the property you entered.`;
        case 3:
          return `The below valuations are estimates of your home's worth.`;
        default:
          return `We were not able to find any estimates on the property you entered.`;
      }
    },
  },
}
</script>

<style scoped lang="scss">
.estimation-boxes {
  margin-bottom: 23px;
}

.estimate-heading {
  font-weight: 300;
}

.disclaimers {

  padding: 23px 0;
  color: #AAAAAA;
  a {
    color: #AAAAAA;
    text-decoration: underline;
  }

  ul {

    padding-left: 2rem;

    li {
      list-style: none;
    }
  }
}

</style>
