<template>

  <CModal
      title="title"
      color="secondary"
      :show="state.isShow"
      size="lg"
  >
    <template slot="header">
      <h4 class="text-left">Address Verification</h4>
      <CButton class="float-right text-white" @click="closeWnd()">X</CButton>
    </template>
    <!--
  <div id="appointment-modal-wnd" class="modal fade bs-example-modal-lg" tabindex="-1" role="dialog"
       aria-labelledby="myLargeModalLabel">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header bg-light">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
              aria-hidden="true">&times;</span></button>
          <h4 class="text-left" v-if="type === MODAL_TYPE_APPOINTMENT">Set Up Appointment</h4>
          <h4 class="text-left" v-if="type === MODAL_TYPE_ASSESSMENT">On-Site Assessment</h4>
        </div>
        <div class="modal-body">
-->
    <div class="row">
      <div class="col col-xs-12 text-left">
        <p class="font-weight-bold" v-if="address">
          Hmmm. We couldn't find an estimate for <span class="">
          {{ address.street_address_1 }},
          {{ address.street_address_2 ? `${address.street_address_2}, ` : '' }}
          {{ address.city }}, {{ address.state }}, {{ address.zip }}</span>
          <br />
          Let's try again. Please correct any information using the form below:
          <br />
          <small>
            Tip: Please make sure to include the full address without abbreviations.
          </small>
        </p>
      </div>
    </div>

    <form class="">

      <CRow class="row" id="request-form">
        <CCol col="12">
          <CRow>
            <div class="col col-xs-12 input-group mb-2">
              <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        Street Address
                                    </span>
              </div>
              <input class="form-control" name="street_address_1" v-model="state.address.street_address_1"
                     component="input"
                     type="text"
                     :class="{ 'is-invalid': $v.state.address.street_address_1.$error }"
                     @change="setValue('street_address_1', $event.target.value)"
              />
              <span class="invalid-feedback"
                    v-if="!$v.state.address.street_address_1.required && $v.state.address.street_address_1.$error">{{
                  messages.required()
                }}</span>
            </div>
          </CRow>
          <CRow>
            <div class="col col-xs-12 input-group mb-2">
              <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        Street Address 2
                                    </span>
              </div>
              <input class="form-control" name="street_address_2" v-model="state.address.street_address_2"
                     component="input"
                     type="text"
                     :class="{ 'is-invalid': $v.state.address.street_address_2.$error }"
                     @change="setValue('street_address_2', $event.target.value)"
              />
              <span class="invalid-feedback"
                    v-if="!$v.state.address.street_address_2.required && $v.state.address.street_address_2.$error">{{
                  messages.required()
                }}</span>
            </div>
          </CRow>
          <CRow>
            <div class="col col-xs-12 input-group mb-2" :class="{ 'is-invalid': $v.state.address.city.$error }">
              <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        City
                                    </span>
              </div>
              <input class="form-control" name="city" v-model="state.address.city" component="input"
                     type="text"
                     :class="{ 'is-invalid': $v.state.address.city.$error }"
                     @change="setValue('city', $event.target.value)"
              />
              <span class="invalid-feedback"
                    v-if="!$v.state.address.city.required && $v.state.address.city.$error">{{
                  messages.required()
                }}</span>
            </div>
          </CRow>
          <CRow>
            <CCol xs="12" lg="6" col="6" class="input-group mb-2"
                  :class="{ 'is-invalid': $v.state.address.state.$error }">
              <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        State
                                    </span>
              </div>

              <input class="form-control" name="state" component="input"
                     type="text"
                     v-model="state.address.state"
                     :class="{ 'is-invalid': $v.state.address.state.$error }"
                     @change="setValue('state', $event.target.value)"
              />


            </CCol>


            <CCol xs="12" lg="6" col="6" class="input-group mb-2">
              <div class="input-group-prepend" :class="{ 'is-invalid': $v.state.address.zip.$error }">
                                    <span class="input-group-text">
                                        Zip
                                    </span>
              </div>
              <input class="form-control" name="zip" v-model="state.address.zip" component="input"
                     type="text"
                     :class="{ 'is-invalid': $v.state.address.zip.$error }"
                     @change="setValue('zip', $event.target.value)"
              />

            </CCol>
            <CCol xs="12" lg="6" col="6" class="pt-0">
                            <span class="invalid-feedback"
                                  :class="{ 'd-inline': $v.state.address.state.$error }"
                                  v-if="!$v.state.address.state.required && $v.state.address.state.$error">{{
                                messages.required()
                              }}</span>
            </CCol>
            <CCol xs="12" lg="6" col="6" class="pt-0" :class="{ 'is-invalid': $v.state.address.zip.$error}">
              <span class="invalid-feedback" :class="{ 'd-inline': $v.state.address.zip.$error }"
                    v-if="!$v.state.address.zip.required && $v.state.address.zip.$error">{{
                  messages.required()
                }}</span>
            </CCol>
          </CRow>

        </CCol>

      </CRow>


    </form>


    <template slot="footer-wrapper">
      <div class="modal-footer bg-light">
        <button type="button" class="btn btn-success" @click="closeWnd()">

          Close
        </button>
        <button type="button" class="btn btn-primary" @click="applyForm()">
          <CSpinner v-if="loading" size="sm" />
          Submit
        </button>
      </div>
    </template>
  </CModal><!-- /.modal-content -->
  <!-- /.modal-dialog -->

</template>

<script>


import { required } from 'vuelidate/lib/validators';
import { mapState } from 'vuex';
import _ from 'lodash';

export default {
  name: 'AddressVerificationModal',
  props: {
    address: Object,
    onClose: Function,
    onSubmit: Function,
    isShow: Boolean,
  },
  data() {
    return {
      title: '',
      state: {
        address: { zip: '', city: '', street_address_2: '', street_address_1: '', state: '' },
        isShow: this.isShow,
      },
      messages: { //Add more messages here when you need, the key should be the same as the name of the validator
        required: () => 'This field is required',
      },
    }
  },
  computed: {
    ...mapState(
        {
          loading: state => state.Property.loading,
        }
    ),
  },
  watch: {
    address: function (newVal) { // newVal , oldVal
      this.state.address = _.pick(newVal, ['zip', 'state','city', 'street_address_1', 'street_address_2' ]);
      //console.dir(this.state.address, this.address);
    },

    state: {

      isShow: {

        handler: function (newVal) {
          // this.state.isShow = newVal;
          if (newVal === false) {
            this.$emit('onClose', true);

          } else {
            // this.state.isShow = newVal;
            this.$v.$reset();
           }
        },
        deep: true,

      },
    },
    isShow: function (newVal) {
      this.state.isShow = newVal;
    },
  },
  methods: {
    closeWnd(){
      this.state.isShow = false;
      this.$emit('onClose', true);
    },
    applyForm() {
      this.$v.$touch()
      if (!this.$v.$invalid) {

        this.$store.dispatch('Property/updateAddress',
                             { contactId: this.address.id, contact: this.state.address }).then(
            (result) => {
              if (result) {
                this.state.isShow = false;
                this.$emit('onClose', true);
              }
            });
      }
    },
    setValue(field, value) {
      this.state.address[field] = value;
    },
  },
  created() {
    this.state.address = _.pick(this.address, ['zip', 'state','city', 'street_address_1', 'street_address_2' ]);
  },
  validations: {
    state: {
      address: {
        street_address_1: {
          required,
        },
        street_address_2: {
          // required,
        },
        city: {
          required,
        },
        state: {
          required,
        },
        zip: {
          required,
        },

      },
    },
  },

}
</script>

<style scoped>
div#request-form .input-group {
  /*padding: 10px;*/
}

div#request-form .input-group-text {
  width: 130px;
  /*max-width: 50px;*/
}
</style>