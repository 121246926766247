<template>
  <CRow align-h="center" class="p-4 text-center d-print-none d-flex justify-content-center hv-landing-search"
        style="background-image: url(https://picsum.photos/900/460/?image=3); background-size: cover;" v-if="contact">
    <CCol xs="12" sm="8" md="5">
      <div class="row">
        <CCol col="12" class="text-white text-center">
          <h2>Search Another Address </h2>
        </CCol>
      </div>
      <CRow>
        <form class="form-inline col col-12">

          <div class="form-group col-xs-12 col-md-10 text-left px-1">
            <label class="sr-only" for="exampleInputEmail3">Property Address, city, state, zip</label>
            <input type="text" class="form-control" id="exampleInputEmail3"
                   placeholder="Property Address, city, state, zip" style="width: 100%"
                   v-model="searchStr">
          </div>

          <div class="col-xs-12 col-md-2 px-1">
            <button type="button" class="btn btn-primary float-left col-xs-offset-5 col-sm-offset-0"
                    variant="primary" @click="runSearch()">
              <CSpinner size="sm" v-if="searchLoader" />
              Search
            </button>
          </div>
        </form>
      </CRow>

    </CCol>
  </CRow>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'SearchBox',
  props: ['agent_id', 'contact'],
  data: function () {
    return {
      searchStr: '',
    }
  },
  computed: {
    ...mapState({
                  campaignId: state => state.Contact.campaignId,
                  searchLoader: state => state.Results.searchLoader,
                }),
  },
  methods: {
    ...mapActions({}),
    runSearch: function () {
      this.$store.dispatch('Tracking/addSearch',
                           { agent_id: this.agent_id, contact_id: this.contact.id, address: this.searchStr, campaign_id: this.campaignId })
      this.$store.dispatch('Results/searchAddress', { contact_id: this.contact.id, address: this.searchStr }).then(() => {
        this.$emit("newAddress", this.searchStr);
      });
    },
  },
}
</script>

<style scoped lang="scss">

.hv-landing-search {
  min-height: 300px;

  h2 {
    font-size: 30px;
    font-weight: 300;
    margin-bottom: 3rem;
  }
}

</style>
