<template>
  <CCard class="estimation-box">
    <CCardBody>

      <h5 class="text-center" v-html="this.$options.label[type]"/>

      <h1 class="value text-center">$ {{ value.estimate['amount'] | formatNumber }}</h1>

        <p class="value text-center"><span class="range">$ {{ value.estimate['low'] | formatNumber}}  - {{ value.estimate['high'] | formatNumber }}</span></p>


      <div class="logo text-center m-0">

        <Logo v-if="!value.site" :type="type"/>
        <a v-if="value.site" :href="value.site" target="_blank">
          <Logo :type="type"/>
        </a>

      </div>
    </CCardBody>
  </CCard>
</template>

<script>
import Logo from '@/components/LandingResult/components/Logo';

export default {
  name: 'ResultValueBox',
  components: { Logo },
  props: {
    type: String,
    site: String,
    value: Object,
  },
  label : {
    'zillow'   : 'Zillow Zestimate&reg;',
    'eppraisal': 'Eppraisal.com Estimate',
    // 'homeworth': 'HomeWorth Estimate',
    // 'attom' : 'Attom Estimate',
    'estated' : 'Estated Estimate',
    'realtyMole': 'Realty Mole'
  },
}
</script>

<style scoped lang="scss">
/*
@import "../../../common/_styles/variables";
@import "../../../common/_styles/breakpoints";
@import "../../../../node_modules/bootstrap/scss/bootstrap";
// @import "../../../assets/scss/style";

.result {
  background: $gray-lighter;

  .agent-details {
    @extend .col-12;
  }

  .team-photo {
    @extend .col-6;
    width: 46%;

    @include breakpoint (bp-xsmall) {
      float: none;
      width: 100%;
    }

    img {
      width: 100%;
    }
  }

  .agent-photo {
    @extend .col-12;
    @extend .col-sm-6;
    //change the grid width slightly
    width: 46%;

    @include breakpoint (bp-xsmall) {
      float: none;
      width: 100%;
    }
  }

  .agent-info {
    @extend .col-12;
    @extend .col-sm-6;
    //change the grid width slightly
    width: 46%;

    @include breakpoint (bp-xsmall) {
      float: none;
      width: 100%;
    }
  }

  .intro-button {
    @extend .col-lg-4;
    @extend .col-md-6;
    @extend .col-12;
  }

  .property-details {
    .details {
      @extend .col-12;
    }
  }

  header {
    background-image: url('../../../assets/images/result_banner.jpg');

    h1,
    p {
      margin-bottom: $line-height-computed / 2;
      text-align: left;
    }
    .header-content {
      padding-bottom: 50px;
      padding-top: 50px;

      .agent-details {
        background-color: rgba(255, 255, 255, 0.85);
        color: $gray-dark;
        margin-top: $line-height-computed;

        p {
          font-size: 14px;
          line-height: 1.5em;
        }

        h3 {
          margin-top: 5px;
        }

        .agent-name {
          font-size: 24px;
        }
      }

      .cta {
        @extend .btn;
        @extend .btn-lg;
        @extend .btn-success;

        margin-top: $line-height-computed;
        //border: 0;
        font-weight: 500;
        width: 100%;
      }

      @include breakpoint (bp-small) {
        padding-top: 30px;
      }
    }
  }


}

 */

.estimation-box {
  border: 1px solid #e7e7e7;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);

  .card-body {
     
     h5 {
          color: #666;
          font-size: 14px;
          font-weight: 500;
          margin-bottom: 23px;
          margin-top: 11.5px;
     }

     h1 {
        font-size: 48px;
        font-weight: 300;
        line-height: .7em;
     }

     .value {

       .range {
          display: block;
          font-size: 14px;
          font-weight: 300;
          margin-bottom: 1rem;
       }

     }
  }

  .logo {
    min-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}


</style>