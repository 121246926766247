<template>

  <CCol class="bg-white p-2" v-if="items.length">
    <h2 class="text-left text-dark px-3 allsales-title">Other Sales in your area</h2>
    <div class="p-2 allsales-table">
      <CDataTable striped hover :items="items" addTableClasses="table-border-none"></CDataTable>
    </div>

  </CCol>

  <!--
  <div class="panel panel-default bg-white">
    <div class="panel-body text-left">
      <h2>Other Sales in your area</h2>
      <table class="table table-striped">
        <thead>
          <tr>
            <th>Property Address</th>
            <th>Property Detail</th>
            <th>Last Sold</th>
            <th>Price</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in items" :key="row.property_address">
            <td v-for="item in row" :key="item">{{ item }}</td>
          </tr>
        </tbody>
      </table>

    </div>
  </div>
  -->
  <!--
  <b-card title="" sub-title="" class="pt-2">

    <b-card-text class="text-left">
      <h2>Other Sales in your area</h2>
    </b-card-text>

    <b-card-text>
      <b-table striped hover :items="items"></b-table>
    </b-card-text>

  </b-card>
  -->

</template>

<script>
import { mapState } from 'vuex';
import numeral from 'numeral';

export default {
  name: 'OtherSales',
  computed: {
    ...mapState({
                  sales: state => state.Results.sales,
                }),
  },
  data() {

    return {
      items: [],
    }
  },
  watch: {
    sales: function (newList) {
      this.items = newList.map(_ => ({
        'Property Address': _.street,
        'Property Details': `${_.bedrooms || '?'}bd • ${_['bathrooms'] || '?'}ba • ${_['finishedSqFt'] || '?'}sqft`,
        'Last Sold': _.lastSoldDate,
        'Price': _['lastSoldPrice'] ? `$ ${numeral(_['lastSoldPrice']).format('0,0')}` : 'No Zillow Data',
      }))
    },
  },
}
</script>

<style scoped lang="scss">

.allsales-title {
  font-size: 1.875rem;
  font-weight: 300;
  margin-top: 1.44rem;
}



</style>